module.exports = [{
      plugin: require('/home/tiago/dev/movimento/campaigns/20anos/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-101299884-2"},
    },{
      plugin: require('/home/tiago/dev/movimento/campaigns/20anos/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/home/tiago/dev/movimento/campaigns/20anos/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
