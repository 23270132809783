import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <main className="main">
        <div className="wrap">
          <h1><Img className="logo" fluid={data.logo.childImageSharp.fluid} /></h1>

          <p><strong>Inscrições online encerradas!</strong> Você pode se credenciar diretamente no local do evento, dia 15/11, a partir das 12h.</p>
        </div>
      </main>

      <div id="sign">
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }

    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
